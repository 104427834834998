export const ADMIN_LOGIN_SEND_OTP_REQUEST = "ADMIN_LOGIN_SEND_OTP_REQUEST"
export const ADMIN_LOGIN_SEND_OTP_SUCCESS = "ADMIN_LOGIN_SEND_OTP_SUCCESS"
export const ADMIN_LOGIN_SEND_OTP_FAIL = "ADMIN_LOGIN_SEND_OTP_FAIL"

export const ADMIN_LOGIN_REQUEST = "ADMIN_LOGIN_REQUEST"
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS"
export const ADMIN_LOGIN_FAIL = "ADMIN_LOGIN_FAIL"

export const USER_LOGIN_SEND_OTP_REQUEST = "USER_LOGIN_SEND_OTP_REQUEST"
export const USER_LOGIN_SEND_OTP_SUCCESS = "USER_LOGIN_SEND_OTP_SUCCESS"
export const USER_LOGIN_SEND_OTP_FAIL = "USER_LOGIN_SEND_OTP_FAIL"

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST"
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS"
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL"

export const USER_SIGNUP_REQUEST = "USER_SIGNUP_REQUEST"
export const USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS"
export const USER_SIGNUP_FAIL = "USER_SIGNUP_FAIL"

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const LOGOUT_FAIL = "LOGOUT_FAIL"

export const CLEAR_ERRORS = "CLEAR_ERRORS"