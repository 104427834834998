import React from 'react';
import HamburgerMenu from '../components/components/HamburgerMenu';

export default function PrivacyPolicy  () {
    return (
        <>
        <HamburgerMenu />
        <div className='static-page-container'>
            <h1>Privacy Policy</h1>
            <p>Last updated: July 21, 2024</p>
            
            <p>This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
            
            <h2>Interpretation and Definitions</h2>
            
            <h3>Interpretation</h3>
            <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
            
            <h3>Definitions</h3>
            <p>For the purposes of this Privacy Policy:</p>
            <ul>
                <li>
                    <strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.
                </li>
                <li>
                    <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Your Company Name.
                </li>
                <li>
                    <strong>Cookies</strong> are small files that are placed on Your computer, mobile device, or any other device by a website, containing the details of Your browsing history on that website among its many uses.
                </li>
                <li>
                    <strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.
                </li>
                <li>
                    <strong>Service</strong> refers to the Website.
                </li>
                <li>
                    <strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
                </li>
                <li>
                    <strong>Website</strong> refers to Your Website, accessible from YourWebsiteURL.
                </li>
                <li>
                    <strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                </li>
            </ul>
            
            <h2>Collecting and Using Your Personal Data</h2>
            
            <h3>Types of Data Collected</h3>
            
            <h4>Personal Data</h4>
            <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
            <ul>
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Phone number</li>
                <li>Address, State, Province, ZIP/Postal code, City</li>
                <li>Usage Data</li>
            </ul>
            
            <h4>Usage Data</h4>
            <p>Usage Data is collected automatically when using the Service.</p>
            <p>Usage Data may include information such as Your Device's Internet Protocol address </p>
        </div>
    </>
    )
}
