export const CREATE_BOOKING_REQUEST = "CREATE_BOOKING_REQUEST"
export const CREATE_BOOKING_SUCCESS = "CREATE_BOOKING_SUCCESS"
export const CREATE_BOOKING_FAIL = "CREATE_BOOKING_FAIL"

export const CANCEL_BOOKING_REQUEST = "CANCEL_BOOKING_REQUEST"
export const CANCEL_BOOKING_SUCCESS = "CANCEL_BOOKING_SUCCESS"
export const CANCEL_BOOKING_FAIL = "CANCEL_BOOKING_FAIL"

export const CONFIRM_BOOKING_STATUS_REQUEST = "CONFIRM_BOOKING_STATUS_REQUEST"
export const CONFIRM_BOOKING_STATUS_SUCCESS = "CONFIRM_BOOKING_STATUS_SUCCESS"
export const CONFIRM_BOOKING_STATUS_FAIL = "CONFIRM_BOOKING_STATUS_FAIL"

export const CREATE_ORDER = "CREATE_ORDER"

export const CLEAR_ERRORS = "CLEAR_ERRORS"